var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "z-page",
    [
      _c(
        "el-breadcrumb",
        {
          staticStyle: { "margin-top": "20px" },
          attrs: { "separator-class": "el-icon-arrow-right" },
        },
        [
          _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
            _vm._v("首页"),
          ]),
          _c("el-breadcrumb-item", [_vm._v("修改包裹信息")]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 30, span: 24 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24, xs: 24, sm: 24, md: 24 } },
                [
                  _c("el-row", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      { staticClass: "container info-card package-info" },
                      [
                        _c("div", { staticClass: "info-header" }, [
                          _c(
                            "svg",
                            {
                              staticClass: "icon",
                              attrs: { "aria-hidden": "true" },
                            },
                            [
                              _c("use", {
                                attrs: { "xlink:href": "#icon-icon_order" },
                              }),
                            ]
                          ),
                          _c("h1", { staticClass: "title" }, [
                            _vm._v("订单信息"),
                          ]),
                        ]),
                        _vm.showPackageInfo
                          ? _c("div", { staticClass: "info-body" }, [
                              _c("div", { staticClass: "info-item" }, [
                                _c(
                                  "label",
                                  { staticClass: "info-item-label" },
                                  [_vm._v("用户编码")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "info-item-content user-code",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.packageData.user_code)),
                                    ]),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "info-item" }, [
                                _c(
                                  "label",
                                  { staticClass: "info-item-label" },
                                  [_vm._v("订单单号")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "info-item-content" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.packageData.package_num) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "info-item" }, [
                                _c(
                                  "label",
                                  { staticClass: "info-item-label" },
                                  [_vm._v("客户昵称")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "info-item-content" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.packageData.user_info.nickName ||
                                            "-"
                                        ) +
                                        " "
                                    ),
                                    _vm.packageData.is_packaging_service
                                      ? _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "item icon-packing",
                                            attrs: {
                                              effect: "dark",
                                              content: "该用户需要收取打包费",
                                              placement: "right-start",
                                            },
                                          },
                                          [
                                            _c(
                                              "svg",
                                              {
                                                staticClass: "icon",
                                                attrs: {
                                                  "aria-hidden": "true",
                                                },
                                              },
                                              [
                                                _c("use", {
                                                  attrs: {
                                                    "xlink:href":
                                                      "#icon-receive",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "info-item" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "info-item-label" },
                                    [_vm._v("客户类型")]
                                  ),
                                  _c(
                                    "el-tag",
                                    {
                                      attrs: {
                                        size: "mini",
                                        type: _vm.formatUserRole(
                                          _vm.packageData.user_role
                                        ).tag_type,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatUserRole(
                                            _vm.packageData.user_role
                                          ).name
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "info-item" }, [
                                _c(
                                  "label",
                                  { staticClass: "info-item-label" },
                                  [_vm._v("微信头像")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "info-item-content" },
                                  [
                                    _vm.packageData.user_info.avatarUrl
                                      ? _c("img", {
                                          staticClass: "avatar",
                                          attrs: {
                                            src: _vm.packageData.user_info
                                              .avatarUrl,
                                          },
                                        })
                                      : _c("p", [_vm._v("-")]),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "info-item" }, [
                                _c(
                                  "label",
                                  { staticClass: "info-item-label" },
                                  [_vm._v("国内快递单号")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "info-item-content" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.packageData.logistics_code) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "info-item" }, [
                                _c(
                                  "label",
                                  { staticClass: "info-item-label" },
                                  [_vm._v("收件国家/地区")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "info-item-content receiver-country",
                                  },
                                  [
                                    _vm.getCountryIcon(_vm.packageData.country)
                                      ? _c("img", {
                                          staticClass: "country-icon",
                                          attrs: {
                                            src: _vm.getCountryIcon(
                                              _vm.packageData.country
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.getCountryNameCn(
                                            _vm.packageData.country
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "info-item" }, [
                                _c(
                                  "label",
                                  { staticClass: "info-item-label" },
                                  [_vm._v("收件人信息")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "info-item-content receiver-info",
                                  },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.packageData.firstname) +
                                          " " +
                                          _vm._s(_vm.packageData.lastname) +
                                          " "
                                      ),
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(_vm.packageData.mobile || "-")
                                      ),
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.packageData.address1) +
                                          " " +
                                          _vm._s(_vm.packageData.address2) +
                                          "," +
                                          _vm._s(_vm.packageData.city) +
                                          "," +
                                          _vm._s(_vm.packageData.state) +
                                          " " +
                                          _vm._s(_vm.packageData.zipcode) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "info-item" }, [
                                _c(
                                  "label",
                                  { staticClass: "info-item-label" },
                                  [_vm._v("包裹保费")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "info-item-content" },
                                  [
                                    _vm._v(
                                      " ¥" +
                                        _vm._s(_vm.packageData.declare_amount) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "info-item" }, [
                                _c(
                                  "label",
                                  { staticClass: "info-item-label" },
                                  [_vm._v("客户下单线路")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "info-item-content" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.packageData.express_title || "-"
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "info-item" }, [
                                _c(
                                  "label",
                                  { staticClass: "info-item-label" },
                                  [_vm._v("订单状态")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "info-item-content" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.packageData.status_label) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "info-item" }, [
                                _c(
                                  "label",
                                  { staticClass: "info-item-label" },
                                  [_vm._v("支付状态")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "info-item-content" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.packageData.status_pay_label
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "info-item" }, [
                                _c(
                                  "label",
                                  { staticClass: "info-item-label" },
                                  [_vm._v("下单时间")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "info-item-content" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("formatDate")(
                                            _vm.packageData.created
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _c("el-col", { attrs: { span: 24, xs: 24, sm: 24, md: 24 } }, [
                _c(
                  "div",
                  { staticClass: "container info-card" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        staticClass: "search-form",
                        attrs: {
                          rules: _vm.validateForm,
                          model: _vm.editForm,
                          "label-width": "90px",
                        },
                        nativeOn: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return (() => {}).apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "form-item-input",
                            attrs: { label: "包裹号", prop: "packageAttrNum" },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                clearable: true,
                                placeholder: "包裹单号",
                              },
                              on: { blur: _vm.getPackage },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.getPackage.apply(null, arguments)
                                },
                              },
                              model: {
                                value: _vm.editForm.packageAttrNum,
                                callback: function ($$v) {
                                  _vm.$set(_vm.editForm, "packageAttrNum", $$v)
                                },
                                expression: "editForm.packageAttrNum",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.packageData.status_pay !== 1
                          ? _c(
                              "el-form-item",
                              {
                                staticClass: "form-item-input",
                                attrs: { label: "价格", prop: "price" },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    clearable: true,
                                    placeholder: "价格",
                                  },
                                  model: {
                                    value: _vm.editForm.price,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.editForm, "price", $$v)
                                    },
                                    expression: "editForm.price",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.packageData.status_pay !== 1
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "线路", prop: "express_id" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      "value-key": "id",
                                      loading: _vm.isExpressLoading,
                                      "no-data-text": "无匹配线路",
                                      placeholder: "请选择线路",
                                      width: 400,
                                      clearable: "",
                                      filterable: "",
                                    },
                                    model: {
                                      value: _vm.editForm.express_id,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editForm,
                                          "express_id",
                                          $$v
                                        )
                                      },
                                      expression: "editForm.express_id",
                                    },
                                  },
                                  _vm._l(_vm.expressList, function (item, i) {
                                    return _c(
                                      "el-option",
                                      {
                                        key: item.id + "-" + i,
                                        attrs: {
                                          label: item.title,
                                          value: item.id,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " [" +
                                            _vm._s(item.id) +
                                            "]" +
                                            _vm._s(item.title) +
                                            " "
                                        ),
                                      ]
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.packageData.status_pay !== 1
                          ? _c(
                              "el-form-item",
                              {
                                staticClass: "form-item-input",
                                attrs: {
                                  label: "计费重量",
                                  prop: "rate_weight",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    clearable: true,
                                    placeholder: "计费重量(kg)",
                                  },
                                  model: {
                                    value: _vm.editForm.rate_weight,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.editForm, "rate_weight", $$v)
                                    },
                                    expression: "editForm.rate_weight",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.packageData.status_pay === 1
                          ? _c(
                              "el-form-item",
                              {
                                staticClass: "form-item-input",
                                attrs: {
                                  label: "包裹保值",
                                  prop: "declare_amount",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    clearable: true,
                                    placeholder: "包裹保值",
                                  },
                                  model: {
                                    value: _vm.editForm.declare_amount,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.editForm,
                                        "declare_amount",
                                        $$v
                                      )
                                    },
                                    expression: "editForm.declare_amount",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.packageData.status_pay !== 1
                          ? _c(
                              "el-form-item",
                              {
                                staticClass: "form-item-input",
                                attrs: { label: "包裹重量", prop: "weight" },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    clearable: true,
                                    placeholder: "包裹重量(kg)",
                                  },
                                  model: {
                                    value: _vm.editForm.weight,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.editForm, "weight", $$v)
                                    },
                                    expression: "editForm.weight",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.packageData.status_pay !== 1
                          ? _c(
                              "el-form-item",
                              {
                                staticClass: "form-item-input-group",
                                attrs: { label: "尺寸", required: "" },
                              },
                              [
                                _c(
                                  "el-col",
                                  {
                                    staticStyle: { "padding-left": "0px" },
                                    attrs: { span: 8 },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { prop: "length" } },
                                      [
                                        _c(
                                          "el-input",
                                          {
                                            attrs: {
                                              type: "number",
                                              min: "0",
                                              clearable: true,
                                              placeholder: "长",
                                            },
                                            model: {
                                              value: _vm.editForm.length,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editForm,
                                                  "length",
                                                  $$v
                                                )
                                              },
                                              expression: "editForm.length",
                                            },
                                          },
                                          [
                                            _c(
                                              "template",
                                              { slot: "prepend" },
                                              [_vm._v("长")]
                                            ),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    staticStyle: { "padding-left": "0px" },
                                    attrs: { span: 8 },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { prop: "width" } },
                                      [
                                        _c(
                                          "el-input",
                                          {
                                            attrs: {
                                              type: "number",
                                              min: "0",
                                              placeholder: "cm",
                                              clearable: "",
                                            },
                                            model: {
                                              value: _vm.editForm.width,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editForm,
                                                  "width",
                                                  $$v
                                                )
                                              },
                                              expression: "editForm.width",
                                            },
                                          },
                                          [
                                            _c(
                                              "template",
                                              { slot: "prepend" },
                                              [_vm._v("宽")]
                                            ),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    staticStyle: { "padding-left": "0px" },
                                    attrs: { span: 8 },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { prop: "height" } },
                                      [
                                        _c(
                                          "el-input",
                                          {
                                            attrs: {
                                              type: "number",
                                              min: "0",
                                              placeholder: "cm",
                                              clearable: "",
                                            },
                                            model: {
                                              value: _vm.editForm.height,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editForm,
                                                  "height",
                                                  $$v
                                                )
                                              },
                                              expression: "editForm.height",
                                            },
                                          },
                                          [
                                            _c(
                                              "template",
                                              { slot: "prepend" },
                                              [_vm._v("高")]
                                            ),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "ctrl-btn" },
            [
              _c(
                "z-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.isLoading,
                      expression: "isLoading",
                    },
                  ],
                  attrs: { type: "primary", disabled: _vm.isLoading },
                  on: { click: _vm.save },
                },
                [_vm._v(_vm._s(_vm.isLoading ? "修改中" : "保存"))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }