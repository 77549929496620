
import { Component, Watch } from 'vue-property-decorator'
import Emitter from '@/utils/emitter'
import { formatDate } from '@/utils/date'
import { getUserRole } from '@/utils/utils'

import { ElForm } from 'element-ui/types/form'
import ZButton from '@/components/base/z-button.vue'
import { Loading } from 'element-ui'

@Component({
	components: { ZButton },
	filters: {
		formatDate(value: number) {
			return value ? formatDate('yyyy-MM-dd h:i:s', value) : '-'
		}
	},
	beforeRouteLeave(to: any, from: any, next: any) {
		this.$confirm('确认离开当前页面？', '提示', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'warning'
		})
			.then(() => {
				next()
			})
			.catch(() => {
				next(false)
			})
	}
})
export default class extends Emitter {
	public $refs!: {
		form: ElForm
	}
	private countryData: any = {}
	private countryList: any = []
	private showPackageInfo = false
	private packageAttrNum = ''
	private isLoading = false
	private isExpressLoading = false
	private expressList = []
	private editForm = {
		packageAttrNum: '',
		price: 0,
		express_id: null,
		rate_weight: '',
		weight:'',
		height:0,
		width:0,
		length:0,
		declare_amount: 0
	}
	private packageData = {
		is_packaging_service: null,
		user_code: '',
		user_role: -1,
		id: null,
		status_pay: 2,
		status: 2,
		express_id: null,
		country: '',
		package_num: '',
		package_attrs: [],
		products: [],
		declare_amount: 0
	}
	private userRoles: any = {}
	private validateForm = {
		packageAttrNum: [
			{ required: true, message: '请输入包裹单号', trigger: 'blur' }
		],
		rate_weight: [{ required: true, message: '请输入计费重量', trigger: 'change' }],
		weight: [{ required: true, message: '请输入包裹重量', trigger: 'change' }],
		length: [{
				required: true,
				validator: (rule: any, value: any, callback: Function) => {
					if (value <= 1) {
						callback(new Error('请输入长，且长不能小于1'))
					} else {
						callback()
					}
				},
				trigger: 'change'
			}],
		width: [
			{
				required: true,
				validator: (rule: any, value: any, callback: Function) => {
					if (value <= 1) {
						callback(new Error('请输入宽，且宽不能小于1'))
					} else {
						callback()
					}
				},
				trigger: 'change'
			}
		],
		height: [{ 
			required: true,
				validator: (rule: any, value: any, callback: Function) => {
					if (value <= 0) {
						callback(new Error('请输入高，且高不能小于0'))
					} else {
						callback()
					}
				},
				trigger: 'change'
		 }],
		price: [
			{
				required: true,
				validator: (rule: any, value: any, callback: Function) => {
					if (value <= 0) {
						callback(new Error('请输入价格'))
					} else {
						callback()
					}
				},
				trigger: 'change'
			}
		],
		declare_amount: [
			{
				required: true,
				validator: (rule: any, value: any, callback: Function) => {
					if (value <= 0) {
						callback(new Error('请输入包裹保值'))
					} else {
						callback()
					}
				},
				trigger: 'change'
			}
		],
		express_id: [
			{
				required: true,
				validator: function(rule: any, value: any, callback: Function) {
					if (!value) {
						callback(new Error('请选择线路'))
					} else {
						callback()
					}
				},
				trigger: 'change'
			}
		]
	}
	formatUserRole(role: number) {
		return this.userRoles[role]
	}
	created() {
		this.getUserRole()
		this.getState()
		this.getCountryList()
	}
	async getUserRole() {
		this.userRoles = await getUserRole()
	}
	async getPackage() {
		const packageAttNum = this.editForm.packageAttrNum
		if (!packageAttNum) return ''

		const loadingInstance = Loading.service({
			fullscreen: true,
			text: '加载中',
			background: 'rgba(0, 0, 0, 0.8)'
		})
		const { data } = await this.$axios
			.get(`v1/jobline/${packageAttNum}/sub-package-info`, {})
			.catch(() => {
				this.$nextTick(() => {
					loadingInstance.close()
				})
				return {
					data: null
				}
			})
		if (!data) {
			return
		}
		this.$nextTick(() => {
			loadingInstance.close()
		})
		await this.getExpress(data.package_id, data.country)
		this.editForm.express_id = data.express_id
		this.editForm.rate_weight = (Number(data.rate_weight) / 1000).toFixed(3)
		this.editForm.weight = (Number(data.weight) / 1000).toFixed(3)
		this.editForm.height = Number(data.height)
		this.editForm.width = Number(data.width)
		this.editForm.length = Number(data.length)
		this.searchPackage(packageAttNum)
		return data
	}
	async searchPackage(logisticsCode: string) {
		const { data } = await this.$axios
			.get(`/v1/jobline/${logisticsCode}/package-info`, {})
			.catch(() => {
				return {
					data: null
				}
			})
			.finally(() => (this.isLoading = false))
		this.$router
			.push({
				query: {
					logistics_code: logisticsCode
				}
			})
			.catch(err => err)
		if (!data) {
			this.packageData.package_num = ''
			return
		}
		this.$set(this, 'packageData', data)
		this.showPackageInfo = true
	}
	getCountryNameCn(country: string) {
		const item = this.countryList.find((v: any) => v.code == country)
		if (item) {
			return `[${item.code}] ${item.name_cn}`
		}
		return this.countryData[country] ? this.countryData[country]['name_cn'] : ''
	}
	getCountryIcon(country: string) {
		return this.countryData[country] ? this.countryData[country]['icon'] : ''
	}
	async getState() {
		const { data } = await this.$axios.get(
			'//res-cn.public.gblinker.com/gblinker/wechat_mini/data/states.json'
		)
		this.countryData = data
	}
	async getCountryList() {
		const { data } = await this.$axios.get(
			'https://res-cn.public.gblinker.com/gblinker/public_data/country-list.json'
		)
		this.countryList = data
	}

	async getExpress(packageId: number, country: string) {
		const { data } = await this.$axios
			.get('v1/jobline/express')
			.finally(() => (this.isExpressLoading = false))
		if (data.length == 0) {
			this.$message.error('无匹配线路')
		}
		this.expressList = data
	}
	async save() {
		const isValidate = await this.$refs['form'].validate().catch(() => false)
		if (!isValidate) return
		const isConfirm = await this.$confirm('确认修改？', '提示', {
			confirmButtonText: '确认',
			cancelButtonText: '取消'
		}).catch(() => false)
		if (!isConfirm) return false

		const packageAttNum = this.editForm.packageAttrNum
		if (this.packageData.status_pay === 1) {
			await this.$axios.post('/v1/jobline/package_attr/change-declare-price', {
				package_attr_num: this.editForm.packageAttrNum,
				declare_amount: this.editForm.declare_amount
			})
			this.searchPackage(packageAttNum)
		} else {
			await this.$axios.post('/package_attr/change-price', {
				package_attr_num: this.editForm.packageAttrNum,
				rate_weight: Math.round(Number(this.editForm.rate_weight) * 1000),
				base_price: this.editForm.price,
				express_id: this.editForm.express_id,
				weight: Math.round(Number(this.editForm.weight) * 1000),
				length: this.editForm.length,
				width: this.editForm.width,
				height: this.editForm.height,
			})
		}
		this.$message.success('修改成功')
	}
}
